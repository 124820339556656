import { useMemo, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { getColumnAlignment } from 'components/utilities'
import { accessors } from './ticket.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const TicketTable = ({ tickets }) => {
	const columns = useMemo(() => accessors(), [])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: tickets },
		useSortBy,
	)

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()

					return (
						<Fragment key={rowKey}>
							<tr {...rowProps}>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td
											key={cellKey}
											{...cellProps}
											className={getColumnAlignment(cell.column.id)}
										>
											{cell.render('Cell')}
										</td>
									)
								})}
							</tr>
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(tickets) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>No tickets found.</p>
	)
}

export default TicketTable
