import { useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { getColumnAlignment } from 'components/utilities'
import { accessors } from './payments.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const PaymentsTable = ({ payments, isClient, onRequestReceipt }) => {
	const columns = useMemo(() => accessors(isClient), [isClient])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: payments, onRequestReceipt },
		useSortBy,
	)

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()

					return (
						<tr key={rowKey} {...rowProps}>
							{row.cells.map((cell) => {
								const { key: cellKey, ...cellProps } = cell.getCellProps()
								return (
									<td
										key={cellKey}
										{...cellProps}
										className={getColumnAlignment(cell.column.id)}
									>
										{cell.render('Cell')}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(payments) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any payments yet.</p>
	)
}

export default PaymentsTable
