import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatCurrency, formatDate } from 'components/utilities'

export const accessors = (isClient) => [
	{
		Header: 'Payments List',
		columns: [
			{
				Header: 'Invoices',
				accessor: (item) => {
					const sortedIds = item.invoiceIds.split(',').sort()
					return (
						<div>
							{sortedIds.map((id, index) => (
								<div key={index}>{id.trim()}</div>
							))}
						</div>
					)
				},
			},
			...(isClient
				? [
						{
							Header: 'Cust ID',
							accessor: (item) => item.customer.custId,
						},
					]
				: []),
			...(isClient
				? [
						{
							Header: 'Customer',
							accessor: (item) => item.customer.name,
						},
					]
				: []),
			{
				Header: 'Payment Date',
				accessor: (item) => formatDate(item.createdAt),
			},
			{
				Header: 'Payment Amount',
				accessor: (item) => formatCurrency(item.total, item.currency),
			},
			...(isClient
				? [
						{
							Header: 'Stripe',
							accessor: (item) => {
								const stripeUrl =
									process.env.NODE_ENV === 'development'
										? 'https://dashboard.stripe.com/test'
										: 'https://dashboard.stripe.com'
								const stripeLink = `${stripeUrl}/payments/${item.paymentIntent}`
								return (
									<>
										<a
											href={stripeLink}
											target="_blank"
											rel="noopener noreferrer"
										>
											{item.paymentIntent}
										</a>
									</>
								)
							},
						},
					]
				: []),
			{
				Header: '⚡',
				accessor: (item) => item,
				Cell: ({ value: item, onRequestReceipt }) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onRequestReceipt(item.customerId, item.paymentIntent)
								}}
							>
								Request Receipt
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
