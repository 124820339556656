// @ts-check

import { useState } from 'react'
import {
	Breadcrumb,
	DropdownButton,
	Dropdown,
	ButtonGroup,
} from 'react-bootstrap'
import Filters from './filters.component'
import UserForm from './user.form.component'
import ContactForm from './contact.form.component'
import BulkUploadForm from './bulkupload.form.component'
import UsersList from './users.list.component'
import { useRefresher } from 'hooks/useRefresher'

/**
 * @param {{ onAction: (action: Action) => void }} props
 */
const HeaderComponent = ({ onAction }) => {
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>User Management</Breadcrumb.Item>
			</Breadcrumb>
			<DropdownButton
				as={ButtonGroup}
				title="User Actions"
				style={{ float: 'right' }}
			>
				<Dropdown.Item
					onClick={() => {
						onAction('create-user')
					}}
				>
					Add New User
				</Dropdown.Item>
				<Dropdown.Item
					onClick={() => {
						onAction('import-contacts')
					}}
				>
					Import Contacts
				</Dropdown.Item>
				<Dropdown.Item
					onClick={() => {
						onAction('activate-contacts')
					}}
				>
					Activate Contacts
				</Dropdown.Item>
				<Dropdown.Item
					onClick={() => {
						onAction('bulk-upload')
					}}
				>
					Bulk Upload
				</Dropdown.Item>
			</DropdownButton>
		</div>
	)
}

const Users = () => {
	const [activeModal, setActiveModal] = useState(
		/** @type {Action | null} */ (null),
	)
	const [isClient, setIsClientOrCustomer] = useState(true)
	const [userData, setUserData] = useState(
		/** @type {UserListUser | null} */ (null),
	)
	const refresher = useRefresher()

	/**
	 * @param {UserListUser} user
	 */
	const showEditForm = (user) => {
		setUserData(user)
		if (user !== null) {
			setActiveModal('edit-user')
		}
	}

	return (
		<>
			<HeaderComponent onAction={setActiveModal} />
			<Filters
				isClient={isClient}
				onClientClick={() => setIsClientOrCustomer(true)}
				onCustomerClick={() => setIsClientOrCustomer(false)}
			/>
			<UsersList
				isClient={isClient}
				onEditUser={showEditForm}
				refresher={refresher}
			/>
			<UserForm
				show={activeModal === 'create-user' || activeModal === 'edit-user'}
				mode={
					activeModal === 'create-user'
						? 'create'
						: activeModal === 'edit-user'
							? 'edit'
							: undefined
				}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
				userData={userData}
			/>
			<ContactForm
				show={
					activeModal === 'activate-contacts' ||
					activeModal === 'import-contacts'
				}
				mode={
					activeModal === 'activate-contacts'
						? 'activate'
						: activeModal === 'import-contacts'
							? 'import'
							: undefined
				}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
			/>
			<BulkUploadForm
				show={activeModal === 'bulk-upload'}
				onClose={() => {
					setActiveModal(null)
				}}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster header="User" />
		</>
	)
}

/**
 * @typedef {'create-user'
 * 	| 'edit-user'
 * 	| 'import-contacts'
 * 	| 'activate-contacts'
 * 	| 'bulk-upload'
 * } Action
 */

export default Users
