import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import QueriesList from './queries.list.component'
import QueryForm from './query.form.component'
import RunQueryForm from './run.query.form.component'
import useAuth from 'hooks/useAuth'
import { useRefresher } from 'hooks/useRefresher'

const HeaderComponent = ({ isAdmin, onAddQuery }) => {
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Queries</Breadcrumb.Item>
			</Breadcrumb>
			{isAdmin && (
				<Button
					style={{ float: 'right' }}
					onClick={() => {
						onAddQuery()
					}}
				>
					Add Query
				</Button>
			)}
		</div>
	)
}

const Queries = () => {
	const [isFormOpen, setIsFormOpen] = useState(false)
	const [isRunFormOpen, setIsRunFormOpen] = useState(false)
	const [mode, setMode] = useState('create')
	const [query, setQuery] = useState(null)
	const refresher = useRefresher()
	const { currentCompany } = useAuth()

	const showEditForm = (query) => {
		setMode('edit')
		setQuery(query)
		setIsFormOpen(true)
	}

	const showRunForm = (query) => {
		setIsRunFormOpen(true)
		setQuery(query)
	}

	return (
		<>
			<HeaderComponent
				isAdmin={currentCompany?.role?.name.toLowerCase() === 'admin'}
				onAddQuery={() => {
					setMode('create')
					setIsFormOpen(true)
				}}
			/>
			<QueriesList
				onEditQuery={showEditForm}
				onRunQuery={showRunForm}
				refresher={refresher}
				companyRole={currentCompany?.role?.name}
			/>
			<QueryForm
				show={isFormOpen}
				mode={mode}
				onClose={() => setIsFormOpen(false)}
				onOperationCompleted={refresher.refresh}
				query={query}
			/>
			{query && (
				<RunQueryForm
					show={isRunFormOpen}
					onClose={() => setIsRunFormOpen(false)}
					query={query}
				/>
			)}
			<refresher.Toaster />
		</>
	)
}

export default Queries
