import { useEffect, useRef, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import useAuth from 'hooks/useAuth'
import { useCustomerService } from 'services/customer.service'
import Select from 'react-select'

const NewUser = ({ onClose, onSubmit }) => {
	const inputRef = useRef(null)
	const { user, currentCompany } = useAuth()
	const [error, setError] = useState(null)
	const [selectedCompanyId, setSelectedCompanyId] = useState(0)
	const [userType, setUserType] = useState('')
	const [companies, setCompanies] = useState([])
	const [customers, setCustomers] = useState([])
	const [customerRoles, setCustomerRoles] = useState([])
	const [clientRoles, setClientRoles] = useState([])
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [customerId, setCustomerId] = useState(0)
	const [roleId, setRoleId] = useState(0)

	const UserDataService = useUserDataService()
	const CustomerService = useCustomerService()

	useEffect(() => {
		setError(null)
		if (UserDataService) {
			Promise.all([
				UserDataService.getRolesByType('Client'),
				UserDataService.getRolesByType('Customer'),
				UserDataService.getAllCompanies(),
			])
				.then(([rolesClient, rolesCustomer, companyData]) => {
					setClientRoles(rolesClient)
					setCustomerRoles(rolesCustomer)
					setCompanies(companyData)
					setSelectedCompanyId(companyData.length === 1 ? companyData[0].id : 0)
				})
				.catch((e) => setError(e))
		}
	}, [UserDataService])

	const submit = (event) => {
		event.preventDefault()

		const newUser = {
			firstName,
			lastName,
			email,
			userType,
			customerId,
			roleId,
			companyId: selectedCompanyId ?? currentCompany.company.id,
		}

		onSubmit(newUser)
	}

	const customerOptions = customers.map((customer) => {
		return {
			value: customer.id,
			label: `${customer.name} (${customer.custId})`,
		}
	})

	useEffect(() => {
		if (!selectedCompanyId) return
		setError(null)
		const company = companies.find(
			(comp) => comp.id === parseInt(selectedCompanyId),
		)
		if (company) {
			CustomerService.findAll({ company }).then((response) =>
				setCustomers(response.data),
			)
		}
	}, [CustomerService, user, companies, currentCompany, selectedCompanyId])

	return (
		<Form onSubmit={submit}>
			<div className="d-grid gap-4">
				{error ? <Alert variant="danger">{error}</Alert> : null}
				<Form.Group className="required">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Enter First Name"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						Please enter first name
					</Form.Control.Feedback>
					<Form.Text className="text-muted">
						Do NOT add any text other than the person's preferred name.
					</Form.Text>
				</Form.Group>
				<Form.Group className="required">
					<Form.Label>Last Name</Form.Label>
					<Form.Control
						required
						type="text"
						placeholder="Enter Last Name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<Form.Control.Feedback type="invalid">
						Please enter last name
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="required">
					<Form.Label>Email</Form.Label>
					<input
						required
						name="email"
						placeholder="Enter email"
						type="email"
						id="email"
						className="form-control"
						ref={inputRef}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></input>
					<Form.Control.Feedback type="invalid">
						{inputRef.current?.validationMessage}
					</Form.Control.Feedback>
				</Form.Group>
				{companies.length > 1 && (
					<Form.Group className="required">
						<Form.Label>Company name</Form.Label>
						<Form.Select
							required
							aria-label="company name"
							value={selectedCompanyId}
							onChange={(e) => setSelectedCompanyId(e.target.value)}
						>
							<option value="">Choose company name</option>
							{companies.map(({ id, name }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}
				<Form.Group className="required">
					<Form.Label>User type</Form.Label>
					<Form.Select
						required
						aria-label="User type: client or customer"
						value={userType}
						onChange={(e) => setUserType(e.target.value)}
					>
						<option value="">Choose user type</option>
						<option value="client">Client</option>
						<option value="customer">Customer</option>
						<option value="salesrep" hidden>
							Sales Rep
						</option>
						<option value="supplier" hidden>
							Supplier
						</option>
					</Form.Select>
				</Form.Group>
				{userType === 'customer' && (
					<>
						<Form.Group className="required">
							<Form.Label>Customer</Form.Label>
							<Select
								required
								value={customerOptions.find((opt) => opt.value === customerId)}
								onChange={(option) => setCustomerId(option.value)}
								options={customerOptions}
							></Select>
						</Form.Group>
					</>
				)}
				<Form.Group className="required">
					<Form.Label>Role</Form.Label>
					<Form.Select
						required
						aria-label="role"
						value={roleId}
						onChange={(e) => setRoleId(e.target.value)}
					>
						<option value="">Choose role</option>
						{(userType === 'customer' ? customerRoles : clientRoles).map(
							({ id, name }) => (
								<option key={id} value={id}>
									{name}
								</option>
							),
						)}
					</Form.Select>
				</Form.Group>

				<Button variant="primary mt-3" type="submit">
					Save
				</Button>
				<Button
					variant="light"
					onClick={() => {
						onClose?.()
					}}
				>
					Cancel
				</Button>
			</div>
		</Form>
	)
}

export default NewUser
