import { Breadcrumb } from 'react-bootstrap'
import ShipmentList from './shipments.list.component'

const HeaderComponent = () => {
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Shipments</Breadcrumb.Item>
			</Breadcrumb>
		</div>
	)
}

const Shipments = () => {
	return (
		<>
			<HeaderComponent />
			<ShipmentList />
		</>
	)
}

export default Shipments
