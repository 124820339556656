import { Badge } from 'react-bootstrap'

export const linesAccessors = [
	{
		Header: 'RMA Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'rMALine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'returnQty',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					item.openDtl ? (
						<Badge bg="success">Open</Badge>
					) : (
						<Badge bg="secondary">Closed</Badge>
					),
			},
		],
	},
]
