// @ts-check

/**
 * @param {string | Date} date
 * @param {Intl.LocalesArgument} [locale]
 */
export function formatDate(date, locale) {
	return new Intl.DateTimeFormat(locale, {
		dateStyle: 'short',
	}).format(new Date(date))
}

/**
 * @param {{ currency?: string, withoutCents?: boolean }} [options]
 */
export function getCurrencyFormat({ currency, withoutCents = false } = {}) {
	return Intl.NumberFormat(navigator.language, {
		style: 'currency',
		currency: currency || 'USD',
		minimumFractionDigits: withoutCents ? 0 : undefined,
		maximumFractionDigits: withoutCents ? 0 : undefined,
	})
}

/**
 * @param {number | bigint} amount
 * @param {string | { currency?: string, withoutCents?: boolean }} [currencyOrOptions]
 */
export function formatCurrency(amount, currencyOrOptions) {
	const options =
		typeof currencyOrOptions === 'string'
			? { currency: currencyOrOptions }
			: currencyOrOptions
	return getCurrencyFormat(options).format(amount)
}

/**
 * @param {string} columnId
 */
export function getColumnAlignment(columnId) {
	return /Amount|Balance|Date|Price/.test(columnId) ? 'text-end' : 'text-start'
}
