import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatDate } from '../utilities'

export const accessors = () => [
	{
		Header: 'RMA List',
		columns: [
			{
				Header: 'RMA #',
				accessor: 'rMANum',
			},
			{
				Header: 'RMA Date',
				accessor: (item) =>
					item.rMADate != null ? formatDate(item.rMADate) : '', // rMADate is how api defines it
			},
			{
				Header: 'Invoice #',
				accessor: 'invoiceNum',
			},
			{
				Header: 'Open RMA',
				accessor: (item) =>
					item.openRMA ? (
						<Badge bg="success">Open</Badge>
					) : (
						<Badge bg="secondary">Closed</Badge>
					),
			},
			{
				Header: '⚡',
				accessor: (item) => item,
				Cell: ({ value: item, onPrintRMA }) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onPrintRMA(item.rMANum)
								}}
							>
								Download RMA
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
