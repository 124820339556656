import { Badge } from 'react-bootstrap'
import { formatDate } from 'components/utilities'

export const accessors = () => [
	{
		Header: 'Ticket List',
		columns: [
			{
				Header: 'Status',
				accessor: 'hs_pipeline_stage',
				Cell: ({ value }) => {
					switch (value) {
						case '1':
							return <Badge bg="success">New</Badge>
						case '2':
							return <Badge bg="warning">Waiting on Customer</Badge>
						case '3':
							return <Badge bg="primary">Waiting on ElevateERP</Badge>
						case '4':
							return <Badge bg="secondary">Closed</Badge>
						default:
							return <Badge bg="danger">Unknown</Badge>
					}
				},
			},
			{
				Header: 'Subject',
				accessor: 'subject',
			},
			{
				Header: 'Priority',
				accessor: (item) => {
					switch (item.hs_ticket_priority) {
						case 'HIGH':
							return 3
						case 'MEDIUM':
							return 2
						case 'LOW':
							return 1
						default:
							return 0
					}
				},
				Cell: ({ value }) => {
					switch (value) {
						case 3:
							return <Badge bg="danger">High</Badge>
						case 2:
							return <Badge bg="warning">Medium</Badge>
						case 1:
							return <Badge bg="success">Low</Badge>
						default:
							return <Badge bg="secondary">Not assigned</Badge>
					}
				},
			},
			{
				Header: 'Create Date',
				accessor: (item) =>
					item.createdate != null ? formatDate(item.createdate) : '',
			},
			{
				Header: 'Last Updated Date',
				accessor: (item) =>
					item.hs_lastmodifieddate != null
						? formatDate(item.hs_lastmodifieddate)
						: '',
			},
		],
	},
]
