import { Badge } from 'react-bootstrap'
import { formatDate } from '../utilities'

export const accessors = () => [
	{
		Header: 'Cases List',
		columns: [
			{
				Header: 'Case#',
				accessor: 'hDCaseNum',
			},
			{
				Header: 'Case Date',
				accessor: (item) =>
					item.createdDate != null ? formatDate(item.createdDate) : '',
			},
			{
				Header: 'Title',
				accessor: 'issueSummary',
			},
			{
				Header: 'Description',
				accessor: (item) => {
					const description = item.issueText
						? new DOMParser().parseFromString(item.issueText, 'text/html').body
								.textContent ?? ''
						: ''
					const formattedDescription = description.replace(/\n/g, '<br />')
					return formattedDescription.length > 100
						? `${formattedDescription.substring(0, 100)}...`
						: formattedDescription
				},
				Cell: ({ value }) => (
					// HTML stripped out in `accessor` above, so this should be safe.
					<span dangerouslySetInnerHTML={{ __html: value }} />
				),
			},
			{
				Header: 'Status',
				accessor: (item) => (
					<Badge bg={item.caseStatus === 'Open' ? 'success' : 'secondary'}>
						{item.caseStatus}
					</Badge>
				),
			},
		],
	},
]
