import { Breadcrumb } from 'react-bootstrap'
import InvoiceList from './invoices.list.component'

const HeaderComponent = () => {
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Invoices</Breadcrumb.Item>
			</Breadcrumb>
		</div>
	)
}

const Invoices = () => {
	return (
		<>
			<HeaderComponent />
			<InvoiceList />
		</>
	)
}

export default Invoices
