import { getPaymentBreakdown } from '@elevate/shared/payments'
import { useState, useEffect, useMemo, useRef } from 'react'
import {
	Alert,
	Modal,
	Button,
	Form,
	Container,
	Row,
	Col,
} from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { getCurrencyFormat } from 'components/utilities'
import { useEpicorInvoiceService } from 'services/epicor'
import StripeComponent from './stripe.component'

const InvoicePaymentModal = ({ customerId, invoice, onClose }) => {
	const EpicorInvoiceService = useEpicorInvoiceService()
	const [paymentAmount, setPaymentAmount] = useState(invoice.invoiceBal)
	const [error, setError] = useState(null)
	const [paymentMethod, setPaymentMethod] = useState('us_bank_account')
	const [clientSecret, setClientSecret] = useState('')
	const [paymentIntentId, setPaymentIntentId] = useState('')
	const [step, setStep] = useState(1)
	const [loading, setLoading] = useState(true)
	const [paymentStatus, setPaymentStatus] = useState('')

	const currency = invoice.currencyCodeCurrencyID || 'USD'

	const currencyFormat = useMemo(
		() => getCurrencyFormat({ currency }),
		[currency],
	)

	const stripePromise = useMemo(() => {
		return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
	}, [])

	const isInitialIntentLoaded = useRef(false)

	const { totalFee, totalAmount } = getPaymentBreakdown(
		paymentAmount,
		currency,
		paymentMethod,
	)

	useEffect(() => {
		if (step !== 3) return
		EpicorInvoiceService.paymentCreate(customerId, invoice.invoiceNum, {
			response: 'succeeded', //TODO: Get actual paymentIntent info
			totalAmount,
			success: true,
			currency: invoice.currencyCodeCurrencyID,
			paymentIntentId,
		})
	}, [
		EpicorInvoiceService,
		customerId,
		invoice.invoiceNum,
		invoice.currencyCodeCurrencyID,
		paymentIntentId,
		step,
		totalAmount,
	])

	useEffect(() => {
		// Since this effect both depends on and sets the `paymentIntentId`, the
		// first time an intent is fetch, the ID will be set and then this effect
		// will run again since the ID has changed, resulting in a double fetch.
		// This ref object tracks that initial load and prevents the extra fetch.
		// All subsequent updates should skip this and fetch the updated intent.
		if (!isInitialIntentLoaded.current && paymentIntentId) {
			isInitialIntentLoaded.current = true
			return
		}

		const fetchPaymentIntent = async () => {
			setError(null)
			setLoading(true)
			try {
				const response = await EpicorInvoiceService.paymentIntent(
					customerId,
					invoice.invoiceNum,
					{
						paymentAmount,
						paymentMethod,
						currency,
						paymentIntentId,
					},
				)
				setClientSecret(response.data.clientSecret)
				setPaymentIntentId(response.data.paymentIntentId)
			} catch (error) {
				setError(`Failed to initialize payment. ${error.message}`)
			} finally {
				setLoading(false)
			}
		}
		fetchPaymentIntent()
	}, [
		EpicorInvoiceService,
		customerId,
		invoice.invoiceNum,
		paymentAmount,
		paymentMethod,
		paymentIntentId,
		currency,
	])

	return (
		<Modal show onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Invoice {invoice.invoiceNum} Payment</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row className="no-gutters">
						<Col
							xs={4}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							Due Date:
						</Col>
						<Col
							xs={8}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							{new Date(invoice.dueDate).toLocaleDateString()}
						</Col>
					</Row>
					<Row className="no-gutters">
						<Col
							xs={4}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							Invoice Amount:
						</Col>
						<Col
							xs={8}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							{currencyFormat.format(invoice.invoiceAmt)}
						</Col>
					</Row>
					<Row className="no-gutters">
						<Col
							xs={4}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							Balance Due:
						</Col>
						<Col
							xs={8}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							{currencyFormat.format(invoice.invoiceBal)}
						</Col>
					</Row>
					<Row className="no-gutters">
						<Col
							xs={4}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							Transaction Fee:
						</Col>
						<Col
							xs={8}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							{currencyFormat.format(totalFee)}
						</Col>
					</Row>
					<Row className="no-gutters">
						<Col
							xs={4}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							Total:
						</Col>
						<Col
							xs={8}
							className="text-end"
							style={{ fontWeight: 'bold', color: '#555' }}
						>
							{currencyFormat.format(totalAmount)}
						</Col>
					</Row>
					<Row>
						{step === 1 && (
							<>
								<Form>
									<Form.Group controlId="paymentAmt">
										<Form.Label>Payment Amount</Form.Label>
										<Form.Control
											type="number"
											step="0.01"
											min="0.01"
											max={Math.min(invoice.invoiceBal, 1000000)}
											value={paymentAmount.toFixed(2)}
											onChange={(event) => {
												setPaymentAmount(Number(event.target.value))
											}}
										/>
									</Form.Group>
									<Form.Group controlId="paymentMethod">
										<Form.Label>Payment Method</Form.Label>
										<Form.Control
											as="select"
											value={paymentMethod}
											onChange={(event) => {
												setPaymentMethod(event.target.value)
											}}
										>
											<option value="card">Card</option>
											<option value="us_bank_account">
												ACH (Bank Transfer)
											</option>
										</Form.Control>
									</Form.Group>
								</Form>
								<Button
									variant="primary"
									className="mt-2"
									onClick={() => setStep(2)}
									disabled={error || loading}
								>
									Next
								</Button>
							</>
						)}

						{step === 2 && clientSecret && (
							<Elements stripe={stripePromise} options={{ clientSecret }}>
								<StripeComponent
									clientSecret={clientSecret}
									totalAmount={totalAmount}
									setStep={setStep}
									setPaymentStatus={setPaymentStatus}
									numberLocale={currencyFormat}
								/>
								<Button
									variant="secondary"
									className="mt-2 me-2"
									onClick={() => setStep(1)}
								>
									Back
								</Button>
							</Elements>
						)}
						{step === 3 && (
							<Alert
								variant={paymentStatus === 'succeeded' ? 'success' : 'warning'}
								className="mt-2 mb-0"
							>
								Payment of {currencyFormat.format(totalAmount)} {paymentStatus}
							</Alert>
						)}
					</Row>
				</Container>
				{error && (
					<Alert variant="danger" className="mt-2 mb-0">
						{error}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default InvoicePaymentModal
