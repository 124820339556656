import { Dropdown, Badge } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'
import { formatCurrency, formatDate } from '../utilities'

export const accessors = () => [
	{
		Header: 'Quotes List',
		columns: [
			{
				Header: 'Quote#',
				accessor: 'quoteNum',
			},
			{
				Header: 'Status',
				accessor: (item) =>
					item.quoteClosed ? (
						<Badge bg="secondary">Closed</Badge>
					) : (
						<Badge bg="success">Open</Badge>
					),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCode',
			},
			{
				Header: 'Quote Date',
				accessor: (item) =>
					item.quoteDate != null ? formatDate(item.entryDate) : '',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null ? formatDate(item.needByDate) : '',
			},
			{
				Header: 'Quote Amount',
				accessor: (item) =>
					formatCurrency(item.docTotalQuote, item.currencyCodeCurrencyID),
			},
			{
				Header: '⚡',
				accessor: (quote) => quote,
				Cell: ({ value: quote, onPrintQuote }) => (
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									onPrintQuote(quote.quoteNum)
								}}
							>
								Download Quote
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				),
			},
		],
	},
]
