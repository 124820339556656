import { useState } from 'react'
import Papa from 'papaparse'
import {
	Alert,
	Button,
	Container,
	Form,
	Modal,
	Row,
	Table,
} from 'react-bootstrap'
import { useUserDataService } from 'services/user.service'
import useAuth from 'hooks/useAuth'

const BulkUploadForm = ({ show = false, onClose, onOperationCompleted }) => {
	const [error, setError] = useState(null)
	const { currentCompany } = useAuth()
	const UserDataService = useUserDataService()
	const [rows, setRows] = useState([])

	const addRow = () => {
		setRows([
			...rows,
			{
				firstName: '',
				lastName: '',
				email: '',
				company: '',
				custId: '',
				roleName: '',
				result: 'pending',
			},
		])
	}

	const deleteRow = (index) => {
		const newRows = rows.filter((_, rowIndex) => rowIndex !== index)
		setRows(newRows)
	}

	const inputChange = (index, field, value) => {
		const newRows = [...rows]
		newRows[index] = {
			...newRows[index],
			[field]: value,
			result: 'pending',
		}
		setRows(newRows)
	}

	const fileUpload = (e) => {
		Papa.parse(e.target.files[0], {
			header: true,
			complete: (results) => {
				const parsedRows = results.data.map((row) => ({
					...row,
					result: 'Pending',
				}))
				setRows((prevRows) => [...prevRows, ...parsedRows])
			},
		})
	}

	const bulkUpload = async () => {
		try {
			const response = await UserDataService.bulkUpload(
				currentCompany.company.id,
				rows,
			)
			onOperationCompleted?.()
			setRows(response.data)
			setError(null)
		} catch (error) {
			setError(error.message)
		}
	}

	const clearState = () => {
		setError(null)
		setRows([])
		onClose?.()
	}

	return (
		<>
			<Modal show={show} onHide={clearState} size="xl">
				<Modal.Header closeButton>
					<Modal.Title>Bulk User Upload</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							{error && (
								<Alert
									variant="danger"
									dismissible
									onClose={() => setError(null)}
								>
									{error}
								</Alert>
							)}
							<Form.Group controlId="formFile" className="mb-3">
								<Form.Label>CSV File</Form.Label>
								<Form.Control type="file" onChange={fileUpload} />
							</Form.Group>
						</Row>
						<Row>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>firstName</th>
										<th>lastName</th>
										<th>email</th>
										<th>company</th>
										<th>custId</th>
										<th>roleName</th>
										<th>result</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{rows.map((row, index) => (
										<tr key={index}>
											<td>
												<Form.Control
													type="text"
													value={row.firstName}
													onChange={(e) =>
														inputChange(index, 'firstName', e.target.value)
													}
												/>
											</td>
											<td>
												<Form.Control
													type="text"
													value={row.lastName}
													onChange={(e) =>
														inputChange(index, 'lastName', e.target.value)
													}
												/>
											</td>
											<td>
												<Form.Control
													type="email"
													value={row.email}
													onChange={(e) =>
														inputChange(index, 'email', e.target.value)
													}
												/>
											</td>
											<td>
												<Form.Control
													type="text"
													value={row.company}
													onChange={(e) =>
														inputChange(index, 'company', e.target.value)
													}
												/>
											</td>
											<td>
												<Form.Control
													type="text"
													value={row.custId}
													onChange={(e) =>
														inputChange(index, 'custId', e.target.value)
													}
												/>
											</td>
											<td>
												<Form.Control
													type="text"
													value={row.roleName}
													onChange={(e) =>
														inputChange(index, 'roleName', e.target.value)
													}
												/>
											</td>
											<td className={getResultClass(row.result)}>
												<ResultLabel row={row} />
											</td>
											<td>
												<Button
													variant="outline-danger"
													className="bi-x-square"
													onClick={() => deleteRow(index)}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Row>
						<Row>
							<Button
								variant="outline-success"
								className="mt-2 me-2"
								onClick={() => addRow()}
							>
								Add Row
							</Button>
							<Button className="mt-2 me-2" onClick={() => bulkUpload()}>
								Upload
							</Button>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	)
}

const getResultClass = (result) => {
	switch (result) {
		case 'pending':
			return ''
		case 'client-exists':
			return 'bg-warning'
		case 'customer-exists':
			return 'bg-warning'
		case 'role-exists':
			return 'bg-warning'
		case 'client-created':
			return 'bg-success'
		case 'customer-created':
			return 'bg-success'
		default:
			return 'bg-danger'
	}
}

const ResultLabel = ({ row }) => {
	switch (row.result) {
		case 'pending':
			return 'Pending'
		case 'client-exists':
			return 'Client Exists'
		case 'customer-exists':
			return 'Customer Exists'
		case 'role-exists':
			return 'Role Already Exists'
		case 'client-created':
			return 'Client Created'
		case 'customer-created':
			return 'Customer Created'
		case 'missing-data':
			return 'Missing Data'
		case 'invalid-email':
			return `Email is invalid: ${row.email}`
		case 'company-missing':
			return `company ${row.company} not found`
		case 'role-missing':
			return `roleName ${row.roleName} not found`
		case 'customer-missing':
			return `custId ${row.custId} and company ${row.company} not found`
		default:
			return row.result
	}
}

export default BulkUploadForm
