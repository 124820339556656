// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorCustomerService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	getInfo(customerId) {
		return this.#http.get(`/api/customers/${customerId}`)
	}

	/**
	 * @param {number} customerId
	 */
	getContacts(customerId) {
		return this.#http.get(`/api/customers/${customerId}/contacts`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} paymentIntentId
	 */
	getPaymentReceipt(customerId, paymentIntentId) {
		if (!paymentIntentId.startsWith('pi_')) {
			throw new Error(
				`Invalid paymentIntent: "${paymentIntentId}". PaymentIntent must start with "pi_".`,
			)
		}
		return this.#http.get(
			`/api/customers/${customerId}/payments/${paymentIntentId}`,
		)
	}
}

export function useEpicorCustomerService() {
	const http = useHttp()
	return useMemo(() => new EpicorCustomerService(http), [http])
}
