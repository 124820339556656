import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import OrderForm from './order.form.component'
import OrdersList from './orders.list.component'

const HeaderComponent = ({ onCreateNewOrder }) => {
	const { settings } = useSettings()
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Orders</Breadcrumb.Item>
			</Breadcrumb>
			{!settings.orderDisableCreate && (
				<Button
					style={{ float: 'right' }}
					onClick={() => {
						onCreateNewOrder()
					}}
				>
					Create New Order
				</Button>
			)}
		</div>
	)
}

const Orders = () => {
	const [showForm, setShowForm] = useState(false)
	const refresher = useRefresher()

	return (
		<>
			<HeaderComponent
				onCreateNewOrder={() => {
					setShowForm(true)
				}}
			/>
			<OrdersList refresher={refresher} />
			<OrderForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
			<refresher.Toaster />
		</>
	)
}

export default Orders
