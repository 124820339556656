// @ts-check

import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'
import { useImpersonation } from './useImpersonation'

/**
 * @param {ApiUser} apiUser
 */
function parseUser(apiUser) {
	return {
		...apiUser,
		customerAndClientCount:
			(apiUser.customers?.length ?? 0) + (apiUser.clients?.length ?? 0),
	}
}

/**
 * @param {User} user
 * @param {string} companyIdCookieString
 * @param {string} isClientCookieString
 */
function findCompanyByIdAndType(
	user,
	companyIdCookieString,
	isClientCookieString,
) {
	const companyId = parseInt(companyIdCookieString, 10)
	const isClient = isClientCookieString === 'true'
	const collection = isClient ? user.clients : user.customers
	const company = collection.find((company) => company.id === companyId)
	return company ? { ...company, isClient } : null
}

const SessionContext = createContext(
	/** @type {SessionContextValue | undefined} */ (undefined),
)

export function useSession() {
	const context = useContext(SessionContext)
	if (context === undefined) {
		throw new Error('useSession must be used within a SessionProvider.')
	}
	return context
}

/**
 * @param {import('react').PropsWithChildren} props
 */
export function SessionProvider({ children }) {
	const {
		impersonatedCustomer,
		setImpersonatedCustomer,
		unsetImpersonatedCustomer,
	} = useImpersonation()

	const [user, setUser] = useState(/** @type {User | null} */ (null))
	const [currentCompany, setCurrentCompany] = useState(
		/** @type {CustomerOrClient | null} */ (null),
	)
	const [loading, setLoading] = useState(true)

	const clearSession = useCallback(() => {
		sessionStorage.clear()
		Cookies.remove('user')
		Cookies.remove('currentCompany')
		// This will clear the cookie via the effect below.
		unsetImpersonatedCustomer()
		setUser(null)
		setCurrentCompany(null)
	}, [unsetImpersonatedCustomer])

	useEffect(() => {
		const accessToken = sessionStorage.getItem('accessToken')
		if (accessToken) {
			const { exp: expirationTimeInSeconds = 0 } = jwtDecode(accessToken)
			const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000

			if (Date.now() >= expirationTimeInMilliseconds) {
				clearSession()
				return
			}

			const timeout = setTimeout(() => {
				clearSession()
			}, expirationTimeInMilliseconds - Date.now())

			fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth`, {
				method: 'GET',
				headers: {
					'x-access-token': accessToken,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setUser(parseUser(data))
					const currentCompanyIdCookie = Cookies.get('currentCompanyId')
					const currentCompanyIsClientCookie = Cookies.get(
						'currentCompanyIsClient',
					)
					if (currentCompanyIdCookie && currentCompanyIsClientCookie) {
						try {
							const currentCompany = findCompanyByIdAndType(
								data,
								currentCompanyIdCookie,
								currentCompanyIsClientCookie,
							)
							setCurrentCompany(currentCompany)
						} catch (error) {
							console.error('Error parsing currentCompany cookie:', error)
							setCurrentCompany(null)
						}
					}
				})
				.catch((error) => {
					console.error('Error fetching user data:', error)
					setUser(null)
					setCurrentCompany(null)
				})
				.finally(() => {
					setLoading(false)
				})

			return () => {
				clearTimeout(timeout)
			}
		} else {
			setLoading(false)
		}
	}, [clearSession])

	// Load the impersonated user into the context from the cookie.
	useEffect(() => {
		const impersonatedCustomerCookie = Cookies.get('impersonatedCustomer')
		if (impersonatedCustomerCookie) {
			let impersonateCustomer
			try {
				impersonateCustomer = JSON.parse(impersonatedCustomerCookie)
			} catch (error) {
				console.error('Error parsing impersonatedCustomer cookie:', error)
			}
			setImpersonatedCustomer(impersonateCustomer)
		}
	}, [setImpersonatedCustomer])

	// Update the cookie based on changes to the impersonated user context.
	useEffect(() => {
		if (impersonatedCustomer) {
			Cookies.set(
				'impersonatedCustomer',
				JSON.stringify(impersonatedCustomer),
				{
					expires: 1,
					sameSite: 'Strict',
					secure: true,
				},
			)
		} else {
			Cookies.remove('impersonatedCustomer')
		}
	}, [impersonatedCustomer])

	const value = {
		user,
		loading,
		currentCompany,
		/**
		 * @param {ApiUser} apiUser
		 */
		setUser(apiUser) {
			if (apiUser.accessToken) {
				sessionStorage.setItem('accessToken', apiUser.accessToken)
			}
			const user = parseUser(apiUser)
			setUser(user)
			return user
		},
		/**
		 * @param {CustomerOrClient} company
		 */
		async setCurrentCompany(company, { isPersisted = false } = {}) {
			if (isPersisted) {
				Cookies.set('currentCompanyId', String(company.id), {
					expires: 1,
					sameSite: 'Strict',
				})
				Cookies.set('currentCompanyIsClient', String(company.isClient), {
					expires: 1,
					sameSite: 'Strict',
				})
			}
			setCurrentCompany(company)
		},
		clearSession,
	}

	return (
		<SessionContext.Provider value={value}>{children}</SessionContext.Provider>
	)
}
