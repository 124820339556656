// @ts-check

import { Modal, Button, Row, Form } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'
import { useImpersonation } from 'hooks/useImpersonation'
import { useCustomerService } from 'services/customer.service'
import Select from 'react-select'

/**
 * @param {{ show: boolean, onSelect(): void, onClose?(): void }} props
 */
const ImpersonateModal = ({ show, onSelect, onClose }) => {
	const [customers, setCustomers] = useState(
		/** @type {readonly Customer[]} */ ([]),
	)
	const [selectedCustomer, setSelectedCustomer] = useState(
		/** @type {Customer | null} */ (null),
	)
	const { setImpersonatedCustomer } = useImpersonation()
	const isLoaded = useRef(false)
	const CustomerService = useCustomerService()

	useEffect(() => {
		if (show && !isLoaded.current) {
			CustomerService.findAll().then((response) => {
				setCustomers(response.data)
				isLoaded.current = true
			})
		}
	}, [CustomerService, show])

	const customerOptions = customers.map((customer) => ({
		value: customer,
		label: `${customer.name} (${customer.custId})`,
	}))

	const impersonate = () => {
		if (selectedCustomer) {
			setImpersonatedCustomer(selectedCustomer)
			onSelect()
		}
	}

	return (
		<Modal
			size="lg"
			show={show}
			animation
			contentClassName="p-3"
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>View as Customer</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="d-grid gap-4 mt-4">
					<Form.Group controlId="form.shipTo">
						<Form.Label>Select customer you want to impersonate</Form.Label>
						<Select
							options={customerOptions}
							onChange={(selectedOption) => {
								setSelectedCustomer(selectedOption?.value ?? null)
							}}
							isSearchable={true}
						/>
					</Form.Group>
					<Button
						variant="primary"
						onClick={impersonate}
						disabled={!selectedCustomer}
					>
						View as Customer
					</Button>
					<Button variant="secondary" onClick={onClose}>
						Cancel
					</Button>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default ImpersonateModal
