// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class TicketService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {CustomerOrClient} currentCompany
	 */
	constructor(http, currentCompany) {
		this.#http = http
		this.#currentCompany = currentCompany
	}

	#http
	#currentCompany

	/**
	 * @param {{ company?: Company }} [options]
	 * @returns {AxiosReturn<readonly Customer[]>}
	 */
	findAll({ company = this.#currentCompany.company } = {}) {
		let url = `/api/environments/${company.environmentId}/company/${company.id}/tickets`
		return this.#http.get(url)
	}
}

export function useTicketService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	if (!currentCompany) {
		throw new Error(
			'Ticket service must have currentCompany loaded in the session.',
		)
	}

	return useMemo(
		() => new TicketService(http, currentCompany),
		[currentCompany, http],
	)
}
