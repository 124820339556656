// @ts-check

/**
 * @param {number} paymentAmount
 * @param {string} currency
 * @param {'card' | 'us_bank_account'} paymentMethod
 */
const getPaymentBreakdown = (paymentAmount, currency, paymentMethod) => {
	const elevateFee = getElevateFee(paymentAmount, currency)
	const { stripeFee, surcharge } = getSurcharge(
		paymentAmount + elevateFee,
		paymentMethod,
	)
	const totalFee = elevateFee + surcharge
	const totalAmount = paymentAmount + totalFee
	return {
		elevateFee,
		stripeFee,
		surcharge,
		totalFee,
		totalAmount,
	}
}

/**
 * @param {number} amount - The payment amount, plus any additional fees.
 * @param {'card' | 'us_bank_account'} paymentMethod
 */
const getSurcharge = (amount, paymentMethod) => {
	// Based on the math (a geometric series) previously provided by Stripe
	// (https://web.archive.org/web/20240525115237/https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers)
	// the amount that should be charged would be:
	//
	// amountWithSurcharge = (amount + stripeFixedFee) / (1 - stripeFeePercent)
	//
	// in order to pass on the fee to the customer as a surcharge. Therefore, the
	// actual surcharge amount would be the difference between this and the
	// original amount:
	//
	// surcharge = amountWithSurcharge - amount
	//
	// which expands and simplifies to:
	//
	// surcharge =
	//  (amount * stripeFeePercent + stripeFixedFee) / (1 - stripeFeePercent) =
	//  stripeFee / (1 - stripeFeePercent)
	//
	const stripeCardFeePercent = 0.029
	const stripeFixedCardFee = 0.3
	const stripeACHFeePercent = 0.008
	const stripeACHFeeMax = 5
	switch (paymentMethod) {
		case 'card': {
			const stripeFee = stripeCardFeePercent * amount + stripeFixedCardFee
			const surcharge = stripeFee / (1 - stripeCardFeePercent)
			return { stripeFee, surcharge }
		}
		case 'us_bank_account': {
			const stripeFee = stripeACHFeePercent * amount
			const surcharge = stripeFee / (1 - stripeACHFeePercent)
			return {
				stripeFee: Math.min(stripeACHFeeMax, stripeFee),
				surcharge: Math.min(stripeACHFeeMax, surcharge),
			}
		}
		default:
			throw new Error(`Unexpected payment method: ${paymentMethod}`)
	}
}

/**
 * @param {number} paymentAmount
 * @param {string} currency
 */
const getElevateFee = (paymentAmount, currency) => {
	//TODO: Set Maximum of 5 USD. Needs currency conversion
	if (currency.toLowerCase() === 'usd')
		return Math.min(paymentAmount * 0.005, 5)
	else return paymentAmount * 0.005
}

/** @param {number} value */
const roundToCents = (value) => Math.round(value * 100) / 100

module.exports = {
	getElevateFee,
	getPaymentBreakdown,
	getSurcharge,
	roundToCents,
}
