// @ts-check

import { Col, Container, Row } from 'react-bootstrap'
import { useImpersonation } from 'hooks/useImpersonation'

const ImpersonatedUserTopBar = () => {
	const { impersonatedCustomer, unsetImpersonatedCustomer } = useImpersonation()

	return (
		<Container>
			<Row className="bg-red text-white">
				<Col className="my-auto mx-auto px-4">
					You are viewing as {impersonatedCustomer?.name} (
					{impersonatedCustomer?.custId})
				</Col>
				<Col xs={2}>
					<u
						className="float-end"
						role="button"
						onClick={() => {
							unsetImpersonatedCustomer()
						}}
					>
						<div className="d-md-none">Exit</div>
						<div className="d-none d-md-block">Exit View As</div>
					</u>
				</Col>
			</Row>
		</Container>
	)
}

export default ImpersonatedUserTopBar
